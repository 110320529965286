import {createApp} from 'vue'
import App from './App.vue'
// 引入路由
import router from './router/router'
// 引入vant
import * as Vant from 'vant';
import 'vant/lib/index.css'; // 引入Vant的样式文件
createApp(App)
    .use(router)
    .use(Vant)
    .mount('#app')
